import { useEffect, useState } from "react";

import { Button } from "antd";

import { SpAjaxUsersService } from "../../ducks/ajax-users/sp-ajax.users.service";
import { ListSpAjaxUsers } from "../../components/ajax-users/list-users";
import { ISpAjaxUser } from "../../ducks/ajax/model";

import AddAjaxUsers from "../../components/ajax-users/create-users";

interface ICreateUserProps {
  spAjaxUsersService: SpAjaxUsersService;
}

const deps: ICreateUserProps = {
  spAjaxUsersService: new SpAjaxUsersService(),
};

const AjaxUsersPage = () => {
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState<ISpAjaxUser[]>([]);

  const getSpAjaxUsers = async () => {
    try {
      const users = await deps.spAjaxUsersService.getSPAjaxUsers();
      if (users.length) {
        setUsers(users);
      }
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSpAjaxUsers();
  }, []);

  return (
    <div>
      <Button onClick={() => setOpen(true)} style={{ marginBottom: 16 }}>
        Добавить пользователя
      </Button>

      <AddAjaxUsers
        isModalOpen={open}
        closeModal={() => setOpen(false)}
        callbackReloadList={() => getSpAjaxUsers()}
      />

      <ListSpAjaxUsers userList={users} />
    </div>
  );
};

export default AjaxUsersPage;
