import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Button, Popconfirm, Space, Table, notification } from "antd";
import type { ColumnsType } from "antd/es/table";
import clientsService from "../../services/clients.service";

type NotificationType = "success" | "info" | "warning" | "error";

interface DataType {
  key: string;
  firstName: string;
  address: string;
  email: string;
  phone: string;
  balance: number;
  _id: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Имя",
    key: "firstName",
    render: (o) => `${o.firstName} ${o.lastName}`,
  },
  {
    title: "E-mail",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Адрес",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "Тел",
    key: "phone",
    dataIndex: "phone",
  },
  {
    title: "Баланс",
    key: "balance",
    dataIndex: "balance",
  },
  {
    title: "Действия",
    key: "action",
    render: (_, record) => (
      <Space size="middle">
        <Link to={`/client-spaces/${record._id}`}>Объекты</Link>
        <Link to={`/client/${record._id}`}>Детали</Link>
        <DeleteClient clientId={record._id} />
      </Space>
    ),
  },
];

const DeleteClient = ({ clientId }: any) => {
  const [api, contextHolder] = notification.useNotification();
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showPopconfirm = () => {
    setOpen(true);
  };

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    desc: string
  ) => {
    api[type]({
      message: `${message}`,
      description: `${desc}`,
    });
  };

  const handleOk = async () => {
    setConfirmLoading(true);

    try {
      setOpen(false);
      setConfirmLoading(false);

      await clientsService.deleteClient(clientId);

      openNotificationWithIcon("success", "Клиент", "Успешно - удален");

      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      setOpen(false);
      setConfirmLoading(false);
      openNotificationWithIcon(
        "error",
        "Клиент",
        `Ошибка при удалении - ${error}`
      );
      console.log(error);
    }
  };

  const handleCancel = () => {
    setConfirmLoading(false);
    setOpen(false);
  };

  return (
    <>
      <Popconfirm
        title="Удаление"
        description="Вы действительно хотите удалить клиента ?"
        open={open}
        onConfirm={handleOk}
        okButtonProps={{ loading: confirmLoading, danger: true }}
        onCancel={handleCancel}
        cancelText={"Отменить"}
        okText={"Удалить"}
      >
        <Button danger onClick={showPopconfirm}>
          Удалить
        </Button>
      </Popconfirm>
      {contextHolder}
    </>
  );
};

export const ClientList = () => {
  const [isLoadClients, setIsLoadClients] = useState<boolean>(false);
  const [clients, setClients] = useState([]);

  const queryClient = async () => {
    setIsLoadClients(true);
    try {
      const clients = await clientsService.fetchClients();
      if (clients.length) setClients(clients);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadClients(false);
    }
  };

  useEffect(() => {
    queryClient();
  }, []);

  return (
    <Table
      size="small"
      columns={columns}
      dataSource={clients}
      loading={isLoadClients}
      rowKey={(i) => i._id}
    />
  );
};
