import { IChannel } from "../models/dahua";
import {
  addCamToHub,
  dahuaAuthCheck,
  getCamDetails,
  getCameraChannelURL,
  getControlPanelURL,
  headers,
  saveCamera,
  updateCameraChannelURL,
  updateCameraDetailURL,
  updateCameraRulesURL,
  updateExecutingRule,
  videoAnalyseRuleURL,
} from "./helpers";

interface IDahuaAuthProps {
  login: string;
  password: string;
  ip: string;
  port: string;
  action_by: string;
}

class DahuaService {
  async verifyAuthorize(props: IDahuaAuthProps) {
    const respone = await fetch(dahuaAuthCheck, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(props),
    });
    return await respone.json();
  }

  async addCumToHub(props: any) {
    const respone = await fetch(addCamToHub, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(props),
    });
    return await respone.json();
  }

  async saveCamera(props: any) {
    const respone = await fetch(saveCamera, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(props),
    });
    return await respone.json();
  }

  async getCamInfo(props: any) {
    const respone = await fetch(getCamDetails, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(props),
    });
    return await respone.json();
  }

  async getCameraChannels(camId: string) {
    const url = getCameraChannelURL(camId);

    const respone = await fetch(url, {
      method: "GET",
      headers: headers,
    });

    return await respone.json();
  }

  async getControlPanelInfo(camId: string) {
    const url = getControlPanelURL(camId);

    const respone = await fetch(url, {
      method: "GET",
      headers: headers,
    });

    return await respone.json();
  }

  async videoAnalyseRule(props: any) {
    const respone = await fetch(videoAnalyseRuleURL, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(props),
    });
    return await respone.json();
  }

  async updateCamera(camera: any) {
    const respone = await fetch(updateCameraDetailURL, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(camera),
    });
    return await respone.json();
  }

  async updateCameraRules(payload: any) {
    const respone = await fetch(updateCameraRulesURL, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(payload),
    });
    return await respone.json();
  }

  async updateHubExecutingRule(props: any) {
    const respone = await fetch(updateExecutingRule, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(props),
    });
    return await respone.json();
  }

  async updateCameraChannel(channel: IChannel, camId: string) {
    const payload = { ...channel, camId };
    const respone = await fetch(updateCameraChannelURL, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(payload),
    });
    return await respone.json();
  }
}

export default new DahuaService();
