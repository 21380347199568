enum AuthAjaxRole {
  USER = "USER",
  PRO = "PRO",
}

export interface IAuthProps {
  login: string;
}

export interface IAjaxAuthProps {
  login: string;
  password: string;
  userRole: string;
}

export interface IAjaxAuthResponseProps {
  sessionToken: string;
  userId: string;
  refreshToken: string;
  message: string;
}

export interface IBindedHub {
  hubId: string;
  hubBindingRole: string;
}

export enum IHubNetworkChannel {
  GSM = "GSM",
  WIFI = "WIFI",
  ETHERNET = "ETHERNET",
}

export enum GroupArmCommands {
  ARM = "ARM",
  DISARM = "DISARM",
}

export interface GroupArmStateProps {
  command: GroupArmCommands;
  ignoreProblems: boolean;
}

export interface IAuthAJAXProps {
  spaceId: string;
  login: string;
  password: string;
  userRole: AuthAjaxRole;
}

export interface IAuthAJAXResponse {
  sessionToken: string;
  userId: string;
  refreshToken: string;
  errors?: [];
  message?: string;
}

export interface IUserInfoProps {
  auth: {
    userId: string;
    sessionToken: string;
  };
  id: string;
}

export interface IHubInfoProps extends IUserInfoProps {
  hubId: string;
}

export enum IHubState {
  DISARMED = "DISARMED",
  ARMED = "ARMED",
  NIGHT_MODE = "NIGHT_MODE",
  ARMED_NIGHT_MODE_ON = "ARMED_NIGHT_MODE_ON",
  ARMED_NIGHT_MODE_OFF = "ARMED_NIGHT_MODE_OFF",
  DISARMED_NIGHT_MODE_ON = "DISARMED_NIGHT_MODE_ON",
  DISARMED_NIGHT_MODE_OFF = "DISARMED_NIGHT_MODE_OFF",
  PARTIALLY_ARMED_NIGHT_MODE_ON = "PARTIALLY_ARMED_NIGHT_MODE_ON",
  PARTIALLY_ARMED_NIGHT_MODE_OFF = "PARTIALLY_ARMED_NIGHT_MODE_OFF",
}

export enum IHubBatteryState {
  CHARGED = "CHARGED",
  DISCHARGED = "DISCHARGED",
  MALFUNCTION = "MALFUNCTION",
}

export interface IHubInfo {
  id: string;
  name: string;
  state: IHubState;
}

export interface IListHub {
  hubId: string;
  spaceId: string;
  userId: string;
}

export interface IHubDetailedProps {
  id: string;
  name: string;
  state: string;
  timeZone: string;
  color: string;
  pingPeriodSeconds: number;
  blockedByServiceProvider: boolean;
  gsm: {
    networkStatus: string;
    signalLevel: null;
    gprsEnabled: boolean;
    disableIcmpBeforeConnecting: boolean;
    virtualOperatorAllowed: boolean;
    roamingEnabled: boolean;
    simCardState: string;
    simCard: {
      number: string;
      apn: string;
      username: string;
      password: string;
      balanceNumber: string;
      trafficTxKb: number;
      trafficRxKb: number;
      lastTrafficResetTimestamp: number;
    };
  };
  cms: {
    address: string;
    port: number;
    alarmRecoveryNotificationSettings: {
      alarmRecoveryReportMode: string;
      additionalAlarmRecoveriesToReport: [];
    };
    pingPeriodSeconds: boolean;
    gprsEnabled: boolean;
    ethernetEnabled: boolean;
    cmsProtocol: string;
    cmsConnectionMode: string;
    siaAccountNumber: string;
    siaEncryptionKey: null;
    siaEncryptionType: string;
    sendPanicButtonLocation: boolean;
    addressReserve: string;
    portReserve: boolean;
    activeChannels: [];
  };
  noiseLevel: {
    avgValueChannel1: number;
    high: boolean;
    avgValueChannel2: number;
  };
  offlineAlarmSeconds: number;
  ledBrightnessLevel: number;
  jeweller: {
    lostHeartbeatsThreshold: number;
    detectorPingIntervalSeconds: number;
  };
  externallyPowered: boolean;
  connectionLostAsMalfunction: boolean;
  alarmAsMalfunctionWhenArming: boolean;
  hubAddress: null;
  hubMalfunctions: [];
  alarmVerification: {
    verificationEnabled: boolean;
    verificationTimeout: number;
  };
  alarmConfirmation: {
    commonDevice: {
      status: string;
      timeoutMinutes: number;
      confirmedAlarmOn: [];
    };
    holdUpDevice: { status: string; timeoutMinutes: number };
  };
  geoFence: null;
  armPreventionConditions: [
    "LOW_BATTERY_CHARGE",
    "NO_EXTERNAL_POWER",
    "TAMPERED",
    "HIGH_NOISE_LEVEL",
    "NO_SERVER_CONNECTION",
    "NO_CMS_CONNECTION"
  ];
  restoreRequiredAfterAlarmCondition: [];
  alarmCondition: [];
  connectivityNotificationSettings: {
    channelOfflineAlarmDelaySeconds: number;
    enabledChannelNotifications: number;
  };
  photoOnDemandDetails: null;
  postAlarmIndication: {
    enabledIndicationRules: [
      "INDICATE_UNCONFIRMED_ALARMS",
      "INDICATE_CONFIRMED_ALARMS"
    ];
  };
  ledIndicationMode: "HUB_SERVER_CONNECTION";
  connectionTestInProgress: boolean;
  imageUrls: null;
  debugLogState: "ETHERNET_ONLY";
  panicSirenOnPanicButton: boolean;
  activeChannels: IHubNetworkChannel;
  warnings: { hub: number; allDevices: number };
  limits: {
    rooms: number;
    groups: number;
    cameras: number;
    sensors: number;
    users: number;
  };
  groupsEnabled: boolean;
  firmware: {
    version: string;
    newVersionAvailable: boolean;
    latestAvailableVersion: string;
    autoupdateEnabled: boolean;
  };
  ethernet: {
    enabled: boolean;
    dns: string;
    ip: string;
    gate: string;
    dhcp: boolean;
    mask: string;
  };
  armPreventionMode: "IGNORE_WARNINGS";
  tampered: boolean;
  panicSirenOnAnyTamper: boolean;
  battery: { chargeLevelPercentage: number; state: IHubBatteryState };
  hardwareVersions: {
    cpu: 1;
    wifi: 0;
    ethernet: 1;
    flash: 2;
    pcb: 7;
    rfm: 1;
    zwave: 0;
    modem: 3;
  };
  fireAlarm: {
    triggerOnAllSensors: boolean;
    doubleImpulses: boolean;
    alarmPropagationTimeoutSeconds: null;
    alarmPropagationTimeoutExpirationTimestamp: 0;
    alarmPropagationState: "DISABLED";
    alarmPropagationDetails: { multiApartmentBuildingModeEnabled: boolean };
  };
  twoStageArming: {
    enabled: boolean;
    applicationTriggeredArming: {
      countdownDurationSeconds: 30;
      state: "NOT_STARTED";
      expirationUnixTime: null;
    };
    deviceTriggeredArming: {
      countdownDurationSeconds: 60;
      finalDoorBounceCountdownDurationSeconds: 3;
      state: "NOT_STARTED";
      expirationUnixTime: null;
    };
  };
  hubSubtype: "HUB";
}

export interface IGroupInfoProps {
  id: string;
  groupName: string;
  imageUrls: {
    small: string;
    medium: string;
    big: string;
  };
  bulkArmInvolved: boolean;
  bulkDisarmInvolved: boolean;
  state: string;
  twoStageArming: {
    enabled: boolean;
    applicationTriggeredArmingState: string;
    deviceTriggeredArmingState: string;
  };
}

export interface IHubSpaceListDataType {
  docId: string;
  id: string;
  name: string;
  state: string;
  battery: {
    chargeLevelPercentage: number;
    state: string;
  };
  activeChannels: IHubNetworkChannel[];
}

export interface IDeleteHubData {
  hubId: string;
  spaceId: string;
}

export type NotificationType = "success" | "info" | "warning" | "error";
