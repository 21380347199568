import { ClientList } from "../../components/clients/client.list";
import { CreateClient } from "../../components/clients/create.client";

const ClientsPage = () => {
  return (
    <>
      <CreateClient />
      <ClientList />
    </>
  );
};

export default ClientsPage;
