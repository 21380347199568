import { Button } from "antd";
import { useState } from "react";
import OperatorCreate from "../../components/operators/operators.create";
import OperatorList from "../../components/operators/operators.list";

const OperatorPage = () => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Button onClick={() => setOpen(true)} style={{ marginBottom: 16}}>
        Создать оператора
      </Button>
      <OperatorCreate isModalOpen={open} closeModal={() => setOpen(false)} />
      <OperatorList />
    </div>
  );
};

export default OperatorPage;
