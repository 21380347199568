import { ISpaceDetail } from "../../models/space";
import {
  headers,
  bindAjaxUserInLocationURL,
  getBindedHubInLocationURL,
  verifyIfBindedAjaxUserURL,
  getBindedHubIdInLocationURL,
  spaceUrl,
} from "../../services/helpers";

export class HubLocationService {
  async getLocationDetail(spaceId: string): Promise<ISpaceDetail> {
    const url = spaceUrl + "/id/" + spaceId;
    const response = await fetch(url, {
      method: "GET",
      headers: headers,
    });
    return await response.json();
  }

  async getBindedHubIdInLocation(spaceId: string, hubId: string): Promise<any> {
    const response = await fetch(getBindedHubIdInLocationURL, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ spaceId, hubId }),
    });
    return await response.json();
  }

  async getBindedHubsInLocation(spaceId: string): Promise<any> {
    const response = await fetch(getBindedHubInLocationURL, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ spaceId }),
    });
    return await response.json();
  }

  async verifyIfBindedAjaxUser(hubId: string): Promise<boolean> {
    const response = await fetch(verifyIfBindedAjaxUserURL, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ hubId }),
    });
    return await response.json();
  }

  async bindAjaxUserInLocation(payload: any): Promise<any> {
    const response = await fetch(bindAjaxUserInLocationURL, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(payload),
    });
    return await response.json();
  }
}
