import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { HubLocationService } from "../../ducks/hub-location/hub.location";

import { ISpaceDetail } from "../../models/space";

interface ICreateUserProps {
  HubLocationService: HubLocationService;
}

const deps: ICreateUserProps = {
  HubLocationService: new HubLocationService(),
};

export const LocationDetails = () => {
  const { id } = useParams();
  const [space, setSpaceDetail] = useState<ISpaceDetail>();

  const getLocationDetailedInfo = async () => {
    try {
      const details = await deps.HubLocationService.getLocationDetail(
        id as string
      );
      setSpaceDetail(details);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLocationDetailedInfo();
  }, [id]);

  return (
    <div style={{ marginBottom: 16 }}>
      <div>Адрес клиента: {space?.address}</div>
      <div>Имя обьекта: {space?.spaceName}</div>
      <div>Адресс объекта: {space?.address}</div>
    </div>
  );
};
