import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { IUser } from "../models/user";

const AuthGuard = ({ children }: any) => {
  const user = useSelector(({ UserSlice }: any) => UserSlice.user as IUser);

  if (!user.email) {
    return <Navigate to="/sign-in" />;
  }

  return <div>{children}</div>;
};

export default AuthGuard;
