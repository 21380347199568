import { IDeleteHubData, IGroupInfoProps } from "../../models/ajax";
import {
  headers,
  spAjaxUsersURL,
  spBindedGroupToCamURL,
} from "../../services/helpers";

import { ISpAjaxUser } from "../ajax/model";

export class SpAjaxUsersService {
  async saveUser(userData: any): Promise<ISpAjaxUser> {
    const response = await fetch(spAjaxUsersURL, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(userData),
    });
    return await response.json();
  }

  async getSPAjaxUsers(): Promise<ISpAjaxUser[]> {
    const response = await fetch(spAjaxUsersURL, {
      method: "GET",
      headers: headers,
    });
    return await response.json();
  }

  async deleteSpUsers(userId: string): Promise<any> {
    const response = await fetch(spAjaxUsersURL, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(userId),
    });
    return await response.json();
  }

  async deleteSpBindedUserHub(deleteData: IDeleteHubData): Promise<any> {
    const response = await fetch(spAjaxUsersURL, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(deleteData),
    });
    return await response.json();
  }

  // get camera groups
  async getBindedCamGroups(hubDocId: string): Promise<IGroupInfoProps[]> {
    const response = await fetch(spBindedGroupToCamURL, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ hubDocId }),
    });
    return await response.json();
  }
}
