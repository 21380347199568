import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

import store from "./redux/store";

import "./index.css";

import AppPage from "./App";

import DashboardPage from "./pages/dashboard";

import ClientsPage from "./pages/client/clients";
import ClientPage from "./pages/client/client";

// import HubCams from "./pages/hubs/hub-cams";

import AuthGuard from "./guards/AuthGuard";
import LoginPage from "./pages/auth/login";
import GuestGuard from "./guards/GuestGuard";
import CamDetails from "./pages/cam/cam.detail";
import OperatorPage from "./pages/operators/operators.page";
import ClientSpaceList from "./pages/spaces/space-client-list";

import AjaxUsersPage from "./pages/ajax-users/AjaxUsers";
import { ClientHubPage } from "./pages/client-hub/client.hub";
import { ClientHubCamera } from "./pages/client-hub-cam/client-hub.cam";
import CameraDetailInfo from "./pages/client-hub-cam/cam.detail";
import DeviceControl from "./pages/client-hub-cam/device.control";
import GroupsTable from "./pages/client-hub-cam/device.control";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthGuard>
        <AppPage />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <DashboardPage />,
      },
      {
        path: "/clients",
        element: <ClientsPage />,
      },
      {
        path: "/client/:id",
        element: <ClientPage />,
      },
      {
        path: "/client-spaces/:id",
        element: <ClientSpaceList />,
      },
      {
        path: "/space-hubs/:id",
        element: <ClientHubPage />,
      },
      {
        path: "/space/:id/hub-cam/:id",
        // element: <HubCams />,
        element: <ClientHubCamera />,
      },
      {
        path: "/cam-info/:id",
        // element: <CamDetails />,
        element: <CameraDetailInfo />,
      },
      {
        path: "/cam-info-table/:id",
        element: <GroupsTable />,
      },
      /* END CLIENTS */
      {
        path: "/operators",
        element: <OperatorPage />,
      },
      /*  */
      {
        path: "/ajax-users",
        element: <AjaxUsersPage />,
      },
    ],
  },
  {
    path: "/sign-in",
    element: (
      <GuestGuard>
        <LoginPage />
      </GuestGuard>
    ),
  },
]);

const persistor = persistStore(store);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <RouterProvider router={router} />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
