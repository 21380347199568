import { ISPCam } from "../../ducks/sp-cam/model";

interface IRenderCameraInfoProps {
  camera: ISPCam;
}

const RenderCameraInfo = ({ camera }: IRenderCameraInfoProps) => {
  return (
    <div style={{ fontWeight: "bold", fontSize: 24, marginBottom: 10 }}>
      {camera?.ip}:{camera?.port} - {camera?.description}
    </div>
  );
};

export default RenderCameraInfo;
