import { useEffect, useState } from "react";
import clientsService from "../../services/clients.service";

import { Button, Popconfirm, Space, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { Link } from "react-router-dom";

interface DataType {
  spaceName: string;
  description: string;
  address: string;
  clientId: string;
  _id: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Наименование",
    key: "spaceName",
    dataIndex: "spaceName",
  },
  {
    title: "Адрес",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "Описание",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Action",
    key: "action",
    render: (_, space) => (
      <Space size="middle">
        <Link to={`/space-hubs/${space._id}`}>Хабы</Link>
        <DeletSpaceComponent spaceId={space._id} />
      </Space>
    ),
  },
];

function DeletSpaceComponent({ spaceId }: any) {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);

    try {
      await clientsService.deleteSpace(spaceId);

      setOpen(false);
      setConfirmLoading(false);
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = () => {
    setConfirmLoading(false);
    setOpen(false);
  };

  return (
    <Popconfirm
      title="Удаление"
      description="Вы действительно хотите удалить Обьект?"
      open={open}
      onConfirm={handleOk}
      okButtonProps={{ loading: confirmLoading, danger: true }}
      onCancel={handleCancel}
      cancelText={"Отменить"}
      okText={"Удалить"}
    >
      <Button danger onClick={showPopconfirm}>
        Удалить
      </Button>
    </Popconfirm>
  );
}

const ClientSpacesList = (props: any) => {
  const [isLoadSpaces, setIsLoadSpaces] = useState<boolean>(false);
  const [clientSpaces, setClientSpaces] = useState([]);

  const queryClientSpaces = async () => {
    setIsLoadSpaces(true);
    try {
      const clientSpaces = await clientsService.fetchClientSpaces(
        props.clientId
      );
      if (clientSpaces.length) setClientSpaces(clientSpaces);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadSpaces(false);
    }
  };

  useEffect(() => {
    queryClientSpaces();
  }, []);

  return (
    <Table
      size="small"
      columns={columns}
      dataSource={clientSpaces}
      loading={isLoadSpaces}
      rowKey={(i) => i._id}
    />
  );
};

export default ClientSpacesList;
