import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Form, Select, Button, notification, Checkbox } from "antd";

import { SpAjaxUsersService } from "../../ducks/ajax-users/sp-ajax.users.service";
import dahuaService from "../../services/dahua.service";
import PtzCascader from "../../components/cam/cascader.ptz";
import { IChannel } from "../../models/dahua";
import { CheckboxChangeEvent } from "antd/es/checkbox";

type NotificationType = "success" | "info" | "warning" | "error";

interface IRule {
  value: number;
  label: string;
  Name: string;
}

interface IBindedCamDetail {
  SpAjaxUsersService: SpAjaxUsersService;
}

const deps: IBindedCamDetail = {
  SpAjaxUsersService: new SpAjaxUsersService(),
};

export const CameraDetailInfo = () => {
  const { id } = useParams();
  const [api, contextHolder] = notification.useNotification();

  const [formRules] = Form.useForm();
  const [formGroups] = Form.useForm();
  const [formChannels] = Form.useForm();

  const [isLoadingRules, setIsloadingRules] = useState<boolean>(false);
  const [isLoadingGroups, setIsLoadingGroups] = useState<boolean>(false);

  const [camRules, setCameraRules] = useState([]);
  const [camera, setCamera] = useState<any>({
    rules: [
      {
        value: "",
      },
    ],
  });

  const [ajaxGroups, setAjaxGroups] = useState<any>([]);
  const [groupSave, setGroupToSave] = useState<any>({});

  const [channels, setChannels] = useState([]);

  const getCameraDetail = async () => {
    const cam = await dahuaService.getCamInfo({ camId: id });

    setCamera(cam);
    setGroupToSave(cam.group);
    setChannels(cam.channels);

    if (cam?.ip && cam.port) {
      setIsLoadingGroups(true);
      await getCameraRules(cam);

      await getCameraGroups(cam.hubDocId);
    }
  };

  const getCameraRules = async (props: any) => {
    setIsloadingRules(true);

    try {
      const data = await dahuaService.videoAnalyseRule(props);
      const response = data.filter((rule: any) => !!rule);
      const rules = response.map((rule: IRule, idx: number) => ({
        value: idx,
        label: rule.Name,
      }));
      setCameraRules(rules);
    } catch (error) {
      console.log("Camera rules", error);
    } finally {
      setIsloadingRules(false);
    }
  };

  const saveCameraRules = () => {
    formRules
      .validateFields()
      .then((values) => {
        const rules = values.selected;
        return rules.map((idx: number) => ({
          enable: `VideoAnalyseRule[0][${String(idx)}].Enable=true`,
          disable: `VideoAnalyseRule[0][${String(idx)}].Enable=false`,
          value: idx,
        }));
      })
      .then((values) => {
       // dahuaService.updateCameraRules(values, camera);
      })
      .then(() => {
        const openNotificationWithIcon = (type: NotificationType) => {
          api[type]({
            message: "Обновление ",
            description: `Правила успешно применены`,
          });
        };
        openNotificationWithIcon("success");
      });
  };

  const setSelectedRules = () => {
    const hasElements = camRules.filter((x: any) =>
      camera.rules.some((y: any) => y.value === x.value)
    );
    formRules.setFieldValue("selected", hasElements);
  };

  const handleChangeRule = (value: any) => {
    formRules.setFieldsValue({ selected: value });
  };

  const setSelectedGroup = () => {
    formGroups.setFieldValue("selected", groupSave);
  };

  const getCameraGroups = async (hubId: string) => {
    try {
      const groups = await deps.SpAjaxUsersService.getBindedCamGroups(hubId);
      const mappedGroups = groups.map((group) => ({
        label: group.groupName,
        value: group.id,
      }));

      setAjaxGroups(mappedGroups);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingGroups(false);
    }
  };

  const handleChangeGroup = (value: any) => {
    formGroups.setFieldsValue(value);
    const hasElements = ajaxGroups.find((x: any) => x.value === value);
    setGroupToSave(hasElements);
  };

  const saveGroupRules = () => {
    formGroups
      .validateFields()
     // .then(() => dahuaService.updateCameraGroup(id as string, groupSave))
      .then(() => {
        const openNotificationWithIcon = (type: NotificationType) => {
          api[type]({
            message: "Обновление",
            description: `Группа успешно обновлена`,
          });
        };
        openNotificationWithIcon("success");
      })
      .catch((err) => console.error("error", err));
  };

  const FormRules = () => (
    <Form
      form={formRules}
      layout="vertical"
      name="form"
      style={{ width: "35%" }}
    >
      <Form.Item name="selected" label="Правила">
        <Select
          mode="multiple"
          allowClear
          onChange={handleChangeRule}
          options={camRules}
          loading={isLoadingRules}
          disabled={isLoadingRules}
        />
      </Form.Item>
      <Button onClick={saveCameraRules}>Сохранить</Button>
    </Form>
  );

  const HubGroup = () => {
    return (
      <Form
        form={formGroups}
        layout="vertical"
        name="form"
        style={{ width: "35%" }}
      >
        <Form.Item name="selected" label="Группа">
          <Select
            allowClear
            onChange={handleChangeGroup}
            options={ajaxGroups}
            loading={isLoadingGroups}
            disabled={isLoadingGroups}
          />
        </Form.Item>
        <Button onClick={saveGroupRules}>Сохранить</Button>
      </Form>
    );
  };

  const handleChangeChannel = async (value: number) => {
    const channel = channels.find((ch: any) => ch.idx === value);
    if (channel) {
      await dahuaService.updateCameraChannel(channel, camera._id);
      window.location.reload();
    }
  };

  const handleChangeArmingExecuteRule = async (rule: any) => {
    await dahuaService.updateCamera({
      ...camera,
      ptz: {
        ...camera.ptz,
        onArming: rule,
      },
    });
    window.location.reload();
  };

  const handleChangeDisarmedExecuteRule = async (rule: any) => {
    await dahuaService.updateCamera({
      ...camera,
      ptz: {
        ...camera.ptz,
        onDisarming: rule,
      },
    });
    window.location.reload();
  };

  const onChangeCheckboxPTZ = async (e: CheckboxChangeEvent) => {
    const checked = e.target.checked;
    await dahuaService.updateCamera({
      ...camera,
      ptz: {
        ...camera.ptz,
        enabled: checked,
      },
    });

    window.location.reload();
  };

  const FormChannel = () => (
    <Form
      form={formChannels}
      layout="vertical"
      name="form"
      style={{ width: "35%" }}
    >
      <Form.Item name="selected" label="Каналы" valuePropName="idx">
        <Select
          allowClear
          onChange={handleChangeChannel}
          options={channels.map((channel: any) => ({
            label: channel.name as string,
            value: channel.idx as number,
          }))}
          value={camera?.ptz?.channel?.idx}
          loading={false}
          disabled={false}
        />
      </Form.Item>
    </Form>
  );

  useEffect(() => {
    getCameraDetail();
  }, []);

  useEffect(() => {
    setSelectedRules();
  }, [camRules]);

  useEffect(() => {
    setSelectedGroup();
  }, [groupSave]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <FormRules />
        <HubGroup />
      </div>

      <div style={{ marginTop: 50 }}>
        <FormChannel />

        {camera?.ptz?.tour && (
          <Checkbox
            onChange={onChangeCheckboxPTZ}
            checked={camera?.ptz?.enabled}
          >
            MultiZone
          </Checkbox>
        )}

        {camera?.ptz?.enabled && (
          <PtzCascader
            onArmingRule={handleChangeArmingExecuteRule}
            onDisarmingRule={handleChangeDisarmedExecuteRule}
            tour={{
              tour: camera?.ptz?.tour,
              tourMax: camera?.ptz?.tourMax,
              tourMin: camera?.ptz?.tourMin,
            }}
            preset={{
              preset: camera?.ptz?.preset,
              presetMax: camera?.ptz?.presetMax,
              presetMin: camera?.ptz?.presetMin,
            }}
            autoScan={{
              autoScan: camera?.ptz?.autoScan,
              autoScanMax: camera?.ptz?.autoScanMax,
              autoScanMin: camera?.ptz?.autoScanMin,
            }}
            pattern={{
              pattern: camera?.ptz?.pattern,
              patternMax: camera?.ptz?.patternMax,
              patternMin: camera?.ptz?.patternMin,
            }}
            onArming={camera?.ptz?.onArming}
            onDisarming={camera?.ptz?.onDisarming}
          />
        )}
      </div>

      {contextHolder}
    </div>
  );
};

export default CameraDetailInfo;
