import { useParams } from "react-router-dom";
import ClientSpacesList from "../../components/spaces/client.spaces.list";
import { CreateSpace } from "../../components/spaces/create.space";

const ClientSpaceList = () => {
  const { id } = useParams();

  return (
    <div>
      <div>
        <h3>Список обьектов</h3>
      </div>
      <CreateSpace clientId={id} />
      <ClientSpacesList clientId={id} />
    </div>
  );
};

export default ClientSpaceList;
