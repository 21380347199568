interface ICascaderOptionParams {
  enabled: boolean;
  max: number;
  min: number;
}

export interface ICascaderTour {
  tour: boolean;
  tourMax: number;
  tourMin: number;
}

export interface ICascaderPreset {
  preset: boolean;
  presetMin: number;
  presetMax: number;
}

export interface ICascaderAutoScan {
  autoScan: boolean;
  autoScanMin: number;
  autoScanMax: number;
}

export interface ICascaderPattern {
  pattern: boolean;
  patternMax: number;
  patternMin: number;
}

export const generateOptionChildren = (
  params: ICascaderOptionParams,
  optionLabel: string
) => {
  if (!params.enabled || params.max < params.min) {
    return [];
  }

  return new Array(params.max - params.min + 1).fill(null).map((_, index) => ({
    label: `${optionLabel} ${index + params.min}`,
    value: index + params.min,
  }));
};
