import { Cascader } from "antd";
import {
  ICascaderTour,
  ICascaderPreset,
  ICascaderAutoScan,
  ICascaderPattern,
  generateOptionChildren,
} from "./cascader.helper";

export interface IPtzCascaderConfigProps {
  onArmingRule: (value: any) => void;
  onDisarmingRule: (value: any) => void;

  tour: ICascaderTour;
  preset: ICascaderPreset;
  autoScan: ICascaderAutoScan;
  pattern: ICascaderPattern;

  onArming: any;
  onDisarming: any;
}

const PtzCascader = (props: IPtzCascaderConfigProps) => {
  const onChangeArming = (value: any) => props.onArmingRule(value);
  const onChangeDisarmed = (value: any) => props.onDisarmingRule(value);

  const ArmedOptions = [
    {
      label: "Tour",
      value: "Tour",
      children: generateOptionChildren(
        {
          enabled: props.tour.tour,
          max: props.tour.tourMax,
          min: props.tour.tourMin,
        },
        "Tour"
      ),
    },
    {
      label: "Preset",
      value: "Preset",
      children: generateOptionChildren(
        {
          enabled: props.preset.preset,
          max: props.preset.presetMax,
          min: props.preset.presetMin,
        },
        "Preset"
      ),
    },
    {
      label: "Scan",
      value: "Scan",
      children: generateOptionChildren(
        {
          enabled: props.autoScan.autoScan,
          max: props.autoScan.autoScanMax,
          min: props.autoScan.autoScanMin,
        },
        "Scan"
      ),
    },
    {
      label: "Pattern",
      value: "Pattern",
      children: generateOptionChildren(
        {
          enabled: props.pattern.pattern,
          max: props.pattern.patternMax,
          min: props.pattern.patternMin,
        },
        "Pattern"
      ),
    },
  ];

  const DisarmedOptions = [
    {
      label: "Tour",
      value: "Tour",
      children: generateOptionChildren(
        {
          enabled: props.tour.tour,
          max: props.tour.tourMax,
          min: props.tour.tourMin,
        },
        "Tour"
      ),
    },
    {
      label: "Preset",
      value: "Preset",
      children: generateOptionChildren(
        {
          enabled: props.preset.preset,
          max: props.preset.presetMax,
          min: props.preset.presetMin,
        },
        "Preset"
      ),
    },
    {
      label: "Scan",
      value: "Scan",
      children: generateOptionChildren(
        {
          enabled: props.autoScan.autoScan,
          max: props.autoScan.autoScanMax,
          min: props.autoScan.autoScanMin,
        },
        "Scan"
      ),
    },
    {
      label: "Pattern",
      value: "Pattern",
      children: generateOptionChildren(
        {
          enabled: props.pattern.pattern,
          max: props.pattern.patternMax,
          min: props.pattern.patternMin,
        },
        "Pattern"
      ),
    },
  ];

  return (
    <div style={{ display: "flex" }}>
      <div>
        <div style={{ marginBottom: 5 }}>Частичная охрана</div>
        <Cascader
          options={ArmedOptions}
          onChange={onChangeArming}
          value={props.onArming}
          maxTagCount="responsive"
          defaultValue={[]}
          style={{ marginRight: 10 }}
        />
      </div>

      <div>
        <div style={{ marginBottom: 5 }}>Снят с охраны</div>
        <Cascader
          options={DisarmedOptions}
          onChange={onChangeDisarmed}
          value={props.onDisarming}
          maxTagCount="responsive"
          defaultValue={[]}
        />
      </div>
    </div>
  );
};

export default PtzCascader;
