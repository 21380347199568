import { clientUrl, headers, spaceUrl } from "./helpers";

class ClientService {
  async fetchClients() {
    const respone = await fetch(clientUrl, {
      method: "GET",
      headers: headers,
    });
    return await respone.json();
  }

  async createClient(client: any) {
    const respone = await fetch(clientUrl, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(client),
    });
    return await respone.text();
  }

  async deleteClient(clientId: string) {
    const respone = await fetch(`${clientUrl + "/" + clientId}`, {
      method: "DELETE",
      headers: headers,
    });
    return await respone.json();
  }
  /* end client */

  async fetchClientSpaces(clientId: string) {
    const respone = await fetch(`${spaceUrl}/${clientId}`, {
      method: "GET",
      headers: headers,
    });
    return await respone.json();
  }

  async createSpace(space: any) {
    const respone = await fetch(spaceUrl, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(space),
    });
    return await respone.json();
  }

  async deleteSpace(spaceId: string) {
    const respone = await fetch(`${spaceUrl}/${spaceId}`, {
      method: "DELETE",
      headers: headers,
    });
    return await respone.json();
  }
}

export default new ClientService();
