import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Popconfirm, Table } from "antd";

import operatorService from "../../services/operator.service";
import useAuthenthication from "../../hooks/useAuthentication";
import { useSelector } from "react-redux";
import { IUser } from "../../models/user";

const columns = [
  {
    title: "Регистрация",
    render: (_: any, u: any) => (
      <div>{moment(u.metadata.creationTime).format("DD-MM-YYYY")}</div>
    ),
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Действия",
    rdataIndex: "email",
    key: "email",
    render: (_: any, u: any) => <UserDeleteComponent uid={u.uid} />,
  },
];

function UserDeleteComponent(prop: any) {
  const { logoutCall } = useAuthenthication();
  const user = useSelector(({ UserSlice }: any) => UserSlice.user as IUser);

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);

    try {
      const response = await operatorService.deleteOperator(prop);
      console.log(response)

      if (user.uid === prop.uid) {
        logoutCall();
      }

      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      console.log(error);
    } finally {
      setOpen(false);
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    setConfirmLoading(false);
    setOpen(false);
  };

  return (
    <Popconfirm
      title="Удаление"
      description="Вы действительно хотите удалить пользователя?"
      open={open}
      onConfirm={handleOk}
      okButtonProps={{ loading: confirmLoading, danger: true }}
      onCancel={handleCancel}
      cancelText={"Отменить"}
      okText={"Удалить"}
    >
      <Button danger onClick={showPopconfirm}>
        Удалить
      </Button>
    </Popconfirm>
  );
}

const OperatorList = () => {
  const [operators, setOperators] = useState([]);

  const fetchOperators = async () => {
    try {
      const users = await operatorService.getOperatorsList();
      setOperators(users);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchOperators();
  }, []);

  return (
    <div>
      {operators && operators.length > 0 && (
        <Table
          size={"small"}
          dataSource={operators}
          columns={columns}
          rowKey={(i: any) => i.email}
        />
      )}
    </div>
  );
};

export default OperatorList;
