import React, { useEffect, useState } from "react";
import { Button, Modal, TreeSelect } from "antd";

const prepareTreeData = (options: any) => {
  return options.map((option: any) => ({
    ...option,
    key: option.value,
    children: option.children.map((child: any) => ({
      ...child,
      key: child.value,
    })),
  }));
};

const findParentValuesForSelectedRules = (
  selectedRulesIds: any,
  treeData: any
) => {
  let parentValues = new Set();

  treeData.forEach((option: any) => {
    option.children.forEach((child: any) => {
      if (selectedRulesIds.includes(String(child.id))) {
        parentValues.add(option.value);
      }
    });
  });

  return Array.from(parentValues);
};

interface IMultiLevelSelectProps {
  options: any[];

  selectedIds: (ids: number[]) => void;

  selectedRulesIds: any[];
}

const MultiLevelSelect = ({
  options,
  selectedIds,
  selectedRulesIds,
}: IMultiLevelSelectProps) => {
  const [data, setData] = useState(options);
  const [modalOpened, setOpenedModal] = useState(false);
  const [value, setValue] = useState<any>([]);

  useEffect(() => {
    const newData = prepareTreeData(options);
    setData(newData);

    const selectedParentValues = findParentValuesForSelectedRules(
      selectedRulesIds,
      newData
    );

    setValue(selectedParentValues);
  }, [selectedRulesIds]);

  const findAllChildrenForParents = (parentValues: any) => {
    let allChildrenIds: any = [];

    options.forEach((option) => {
      if (parentValues.includes(option.value)) {
        allChildrenIds = [
          ...allChildrenIds,
          ...option.children.map((child: any) => child.id),
        ];
      }
    });

    return allChildrenIds;
  };

  const onChange = (selectedParentValues: never[]) => {
    const selectedRuleIds = findAllChildrenForParents(selectedParentValues);
    selectedIds(selectedRuleIds);

    setValue(selectedParentValues);
  };

  const onCancelModalPress = () => {
    setOpenedModal(false);
  };

  const onOkModalPress = () => {
    setOpenedModal(false);
  };

  return (
    <>
      <Button onClick={() => setOpenedModal(!modalOpened)}>Настроить</Button>
      <Modal
        open={modalOpened}
        onCancel={onCancelModalPress}
        onOk={onOkModalPress}
        title={"Выберите правила"}
      >
        <div style={{ paddingTop: 20, paddingBottom: 20 }}>
          <TreeSelect
            style={{ width: "100%" }}
            value={value}
            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
            treeData={data || []}
            placeholder="Выберите правила"
            multiple
            maxTagCount={"responsive"}
            treeCheckable={true}
            showCheckedStrategy={TreeSelect.SHOW_PARENT}
            onChange={(props) => onChange(props)}
          />
        </div>
      </Modal>
    </>
  );
};

export default MultiLevelSelect;
