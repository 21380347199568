import {
  ajaxAutorizeURL,
  ajaxBindedHubDetailedActualURL,
  ajaxBindedHubDetailedURL,
  ajaxBindedHubURL,
  headers,
} from "../../services/helpers";

interface IAjaxAuthProps {
  login: string;
  password: string;
  userRole: string;
}

interface IAjaxAuthResponse {
  sessionToken: string;
  userId: string;
  refreshToken: string;
  messageId?: string;
  message?: string;
  errors?: any[];
}

export class AjaxService {
  async authorize(auth: IAjaxAuthProps): Promise<IAjaxAuthResponse> {
    const response = await fetch(ajaxAutorizeURL, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(auth),
    });
    return await response.json();
  }

  async bindedHubs(auth: any): Promise<any> {
    if (!auth.login) return;

    const response = await fetch(ajaxBindedHubURL, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(auth),
    });
    return await response.json();
  }

  async bindedHubDetail(auth: any, bindedHubs: any[]): Promise<any> {
    const response = await fetch(ajaxBindedHubDetailedURL, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ auth, bindedHubs }),
    });
    return await response.json();
  }

  async bindedHubDetailActual(bindedHubs: any[]): Promise<any> {
    const response = await fetch(ajaxBindedHubDetailedActualURL, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(bindedHubs),
    });
    return await response.json();
  }
}
