import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { ISpAjaxUser } from "../../ducks/ajax/model";
import { SpAjaxUsersService } from "../../ducks/ajax-users/sp-ajax.users.service";
import { HubLocationService } from "../../ducks/hub-location/hub.location";

import { BindUserInSpace } from "./bind.user.space";
import { AjaxHubSpaceList } from "./ajax-hub.space.list";
import { LocationDetails } from "./space.details";

interface ICreateUserProps {
  HubLocationService: HubLocationService;
  spAjaxUsersService: SpAjaxUsersService;
}

const deps: ICreateUserProps = {
  HubLocationService: new HubLocationService(),
  spAjaxUsersService: new SpAjaxUsersService(),
};

export const ClientHubPage = () => {
  const [users, setUsers] = useState<ISpAjaxUser[]>([]);

  const getAvailableSpAjaxUsers = async () => {
    const spUsers = await deps.spAjaxUsersService.getSPAjaxUsers();
    setUsers(spUsers as ISpAjaxUser[]);
  };

  useEffect(() => {
    getAvailableSpAjaxUsers();
  }, []);

  return (
    <div>
      <LocationDetails />
      <BindUserInSpace users={users} />
      <AjaxHubSpaceList />
    </div>
  );
};
