import { Form, Input, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import operatorService from "../../services/operator.service";
import { User } from "firebase/auth";

interface IUserCreateModal {
  isModalOpen: boolean;
  closeModal: () => void;
  sendNotification?: (type: UserResponse) => void;
}

export enum UserResponse {
  IsRegistred = 1,
  Success = "_writeTime",
  InvalidEmail = 3,
  Invalid1C = 4,
}

const OperatorCreate = (props: IUserCreateModal) => {
  const { isModalOpen, closeModal } = props;
  const [form] = Form.useForm();
  const [error, setError] = useState<any>();

  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);

  const handleCancel = () => closeModal();

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => createUser(values))
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const createUser = async (values: any) => {
    setConfirmLoading(true);
    setError(undefined);

    try {
      const res = await operatorService.createOperator(values);
      if (res.email) {
        setConfirmLoading(false);
        window.location.reload();
      } else {
        setError(res.message);
      }
    } catch (error) {
      setError(error);
      console.log(error);
    } finally {
      setConfirmLoading(false);
    }
  };

  return (
    <Modal
      title="Регистрация пользователя"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={"Зарегистрировать"}
      cancelText={"Отмена"}
      confirmLoading={confirmLoading}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ type: "cloud" }}
      >
        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            {
              required: true,
              message: "E-mail обязателен!",
            },
          ]}
        >
          <Input type="email" placeholder="example@example.com" />
        </Form.Item>
      </Form>

      {error && <div>{error}</div>}
    </Modal>
  );
};

export default OperatorCreate;
