import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

export const firebaseConfig = {
  apiKey: "AIzaSyDUyRRVMF1ZW5j2xzZgG0V1-o6N3Kh9cAI",
  authDomain: "securitypark-app-staging.firebaseapp.com",
  projectId: "securitypark-app-staging",
  storageBucket: "securitypark-app-staging.appspot.com",
  messagingSenderId: "319335983245",
  appId: "1:319335983245:web:025f3dc6d50c4760865ac4",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export default app;
