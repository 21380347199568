import {
  createOperatorURL,
  deleteOperatorURL,
  getOperatorList,
  headers,
} from "./helpers";

class OperatorService {
  async getOperatorsList() {
    const respone = await fetch(getOperatorList, {
      method: "GET",
      headers: headers,
    });
    return await respone.json();
  }

  async createOperator(props: any) {
    const respone = await fetch(createOperatorURL, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(props),
    });
    return await respone.json();
  }

  async deleteOperator(props: any) {
    const respone = await fetch(deleteOperatorURL, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(props),
    });
    return await respone.text();
  }
}

export default new OperatorService();
