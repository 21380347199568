import { useParams } from "react-router-dom";
import { CameraList } from "./cam.list";
import AddCameraToHub from "./add.camera";

export const ClientHubCamera = () => {
  const { id } = useParams();

  return (
    <div>
      <div>Камеры у хаба</div>
      <div>
        <AddCameraToHub hubId={id} />
        <CameraList hubId={id} />
      </div>
    </div>
  );
};
