import { useState } from "react";

import { Form, Input, Modal, Select, notification } from "antd";
import { AjaxService } from "../../ducks/ajax/ajax.service";
import { IAjaxAuthProps } from "../../ducks/ajax/model";
import { hash256 } from "../../ducks/hash/crypto";
import { SpAjaxUsersService } from "../../ducks/ajax-users/sp-ajax.users.service";
import { NotificationType } from "../../models/ajax";

export enum UserResponse {
  IsRegistred = 1,
  Success = "_writeTime",
  InvalidEmail = 3,
  Invalid1C = 4,
}

interface IUserCreateModal {
  isModalOpen: boolean;
  closeModal: () => void;
  callbackReloadList: (type: number) => void;
}

interface ICreateUserProps {
  ajaxService: AjaxService;
  spAjaxUsersService: SpAjaxUsersService;
}

const deps: ICreateUserProps = {
  ajaxService: new AjaxService(),
  spAjaxUsersService: new SpAjaxUsersService(),
};

const AddAjaxUsers = (props: IUserCreateModal) => {
  const { isModalOpen, closeModal, callbackReloadList } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();

  const handleChange = (value: string) => {
    form.setFieldsValue({ userRole: value });
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => addUserInList(values))
      .catch((info) => console.log("Validate Failed:", info));
  };

  const handleCancel = () => closeModal();

  const addUserInList = async (authProps: IAjaxAuthProps) => {
    setIsLoading(true);

    try {
      authProps.passwordHash = hash256(authProps.password);
      const authResponse = await deps.ajaxService.authorize(authProps);

      if (!authResponse.userId) {
        const openNotificationWithIcon = (type: NotificationType) => {
          api[type]({
            message: "Service Ajax Systems",
            description: authResponse?.message,
          });
        };
        openNotificationWithIcon("error");
      } else {
        const userData = { ...authResponse, ...authProps };
        await deps.spAjaxUsersService.saveUser(userData);
      }

      callbackReloadList(1);
    } catch (error: any) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title="Добавление пользователя"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={"Добавить"}
      cancelText={"Отмена"}
      confirmLoading={isLoading}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ userRole: "USER" }}
      >
        <Form.Item
          name="login"
          label="E-mail"
          rules={[
            {
              required: true,
              message: "E-mail обязателен!",
            },
          ]}
        >
          <Input type="email" placeholder="example@example.com" />
        </Form.Item>

        <Form.Item
          name="password"
          label="Пароль"
          rules={[
            {
              required: true,
              message: "Пароль обязателен!",
            },
          ]}
        >
          <Input type="password" placeholder="***********" />
        </Form.Item>

        <Form.Item
          name="userRole"
          label="Тип AJAX пользователя"
          className="collection-create-form_last-form-item"
          rules={[
            {
              required: true,
              message: "Тип пользователя обязателен",
            },
          ]}
        >
          <Select
            onChange={handleChange}
            options={[
              { value: "USER", label: "USER" },
              { value: "PRO", label: "PRO" },
            ]}
          />
        </Form.Item>
      </Form>
      {contextHolder}
    </Modal>
  );
};

export default AddAjaxUsers;
