import { Button, Popconfirm, Space, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import {
  deleteCameraFromHub,
  fetchCamsInHub,
  headers,
} from "../../services/helpers";

interface DataType {
  camId: string;
  hubId: string;
  ip: string;
  port: string;
  group:
    | {
        label: string;
        value: string;
      }
    | undefined;
  rules: [] | undefined;
}

const DeleteCameraComponent = (camId: any) => {
  const { id } = useParams();
  const hubId = id;

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);

    try {
      const response = await fetch(deleteCameraFromHub, {
        headers: headers,
        method: "POST",
        body: JSON.stringify({ ...camId, hubId }),
      });

      await response.json();
      setOpen(false);
      setConfirmLoading(false);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = () => {
    setConfirmLoading(false);
    setOpen(false);
  };

  return (
    <Popconfirm
      title="Удаление"
      description="Вы действительно хотите удалить камеру ?"
      open={open}
      onConfirm={handleOk}
      okButtonProps={{ loading: confirmLoading, danger: true }}
      onCancel={handleCancel}
      cancelText={"Отменить"}
      okText={"Удалить"}
    >
      <Button danger onClick={showPopconfirm}>
        Удалить
      </Button>
    </Popconfirm>
  );
};

const columns: ColumnsType<DataType> = [
  {
    title: "Описание",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "IP",
    dataIndex: "ip",
    key: "ip",
    render: (_, cam) => String(cam.ip + ":" + cam.port),
  },
  {
    title: "Кол-во - правил",
    key: "rules",
    render: (_, cam) => cam.rules?.length,
  },
  {
    title: "Группа",
    key: "rules",
    render: (_, cam) => cam.group?.label || "Неопределено",
  },
  {
    title: "Действия",
    key: "action",
    render: (_, cam) => (
      <Space size="middle">
        <Link to={`/cam-info-table/${cam.camId}`}>Настройки</Link>
        <DeleteCameraComponent camId={cam.camId} hubId={cam.hubId} />
      </Space>
    ),
  },
];

interface ICamListProps {
  hubId: string | undefined;
}

export const CameraList = (props: ICamListProps) => {
  const [camList, setCamList] = useState([]);

  const queryCamList = async () => {
    try {
      const response = await fetch(fetchCamsInHub, {
        headers: headers,
        method: "POST",
        body: JSON.stringify({ hubId: props.hubId }),
      });
      const data = await response.json();
      setCamList(data);
    } catch (error) {
      setCamList([]);
    }
  };

  useEffect(() => {
    queryCamList();
  }, []);

  return (
    <div>
      {!!camList.length && (
        <Table
          size="small"
          columns={columns}
          dataSource={camList}
          rowKey={(i) => i.camId}
        />
      )}
    </div>
  );
};
