import { Table } from "antd";
import { ISpAjaxUser } from "../../ducks/ajax/model";
import { ColumnsType } from "antd/es/table";

interface ISpAjaxUserListProps {
  userList: ISpAjaxUser[];
}

const columns: ColumnsType<ISpAjaxUser> = [
  {
    title: "Id",
    dataIndex: "userId",
    key: "userId",
  },
  {
    title: "E-mail",
    dataIndex: "login",
    key: "login",
  },
  {
    title: "Действия",
    key: "action",
  },
];

export const ListSpAjaxUsers = (props: ISpAjaxUserListProps) => {
  return (
    <Table
      size="small"
      columns={columns}
      dataSource={props.userList}
      rowKey={(u) => u.userId}
    />
  );
};
