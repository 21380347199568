import { IHubState } from "../../models/ajax";

interface IRenderHubStateProps {
  state: IHubState;
}

const RenderHubState = ({ state }: IRenderHubStateProps) => {
  return (
    <>
      {state === IHubState.ARMED ? (
        <div
          style={{
            borderRadius: 16,
            backgroundColor: "#fe7763",
            padding: 5,
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Под охраной
        </div>
      ) : (
        <div
          style={{
            borderRadius: 16,
            backgroundColor: "#5ee0a6",
            padding: 5,
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Снят с охраны
        </div>
      )}
    </>
  );
};

export default RenderHubState;
